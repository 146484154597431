
.rechargeCard {
    width: 90px;
    height: 110px;
    background-color: #f4f4f4;
    margin-right: 10px;
    margin-top: 10px;
    display: inline-block;
    border-radius: 5px;
    flex-shrink: 0;
  }
  
  .dark.rechargeCard {
    background-color: #2c2c2c;
  }
  
  .rechargeCardTopLine {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .rechargeCardCoinCnt {
    font-size: 22px;
    font-weight: 600;
  }
  
  .rechargeCardCoinIcon {
    width: 20px;
    height: 20px;
    margin-left: 3px;
  }
  
  .rechargeCardBottomLine {
    margin-top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
  }
  
  .rechargeCardActive {
    color: #eabd38;
    border: 1px solid gold;
  }
  