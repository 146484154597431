.pageBody {
    :global(.adm-button-large) {
        font-weight: 900;
        width: 98vw;
        margin: 3vw 0 0 1vw;
        border: none;
    }
    --user-info-card-margin: 10px;
}

.rechargeCoinDesc {
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
    margin-left: 10px;
}

.rechargeCardList {
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.openButton {
    --adm-font-size-10: 20px;
    --background-color: #eabd38 !important;
    --border-radius: 25px !important;
}


.noticeDesc {
    text-align: center;
    font-size: 13px;
    opacity: 0.8;
    margin-top: 5px;
}


.userInfoHintMsg {
    margin-left: 10px;
    margin-top: 10px;
    opacity: 0.8;
}