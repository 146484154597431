
.emptyDesc {
    font-size: 16px;
    text-align: center;
    margin-top: 100px;
    color: #808080;
}

.detailTopLine {
    display: flex;
    justify-content: space-between;
}

.detailBodyLine {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 5px 0 0 10px;
}

.priceWrapper {
    display: flex;
}

.boxDesc {
    color: #c9789b;
}

.orderPrice {
    color: #63ad4c;
    width: 100px;
    text-align: right;
}

.brokerage {
    color: #63ad4c;
    width: 100px;
    text-align: right;
}
